@import "../../libs/mixing";
@import '../../libs/button';

.login-button-style {
  width: 20em;
  height: 3em;
  background-color: transparent;
  color: white;
  text-align: center;
  padding: 0.5em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  border: 2px solid white;
  cursor: pointer;
}
.custom-button-style {
  @include button-style-component(white, rgb(89, 98, 101));
  margin: 0.5em;
  // color: black;
  width: 7em;
  height: 2.5em;
  cursor: pointer;
}
.custom-small-button-style {
  @include green_button();
}
.custom-small-button-red {
  @include crinsom_button();
}
.custom-xsmall-button-red{
  @include small_button();
  width: auto;
  height: 2em;
  font-size: .5em;
  margin: 0;
  padding: .6em;
  text-align: center;
  border: 1px solid red;
  color: red;


}
.custom-xsmall-button-green{
  @include small_button();
  width: auto;
  height: 2em;
  font-size: .5em;
  margin: 0;
  padding: .6em;
  text-align: center;
  border: 1px solid green;
  color: green;


}
.custom-medium-button-style {
  @include crinsom_button();
  
}
.custom-large-button-style {
  @include ruby_button();
 
}
