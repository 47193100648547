@mixin button-style {
  border: none;
  border-radius: 5px;
  font-size: 0.6em;
  margin: 0.3em;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
@mixin small_button() {
  background: transparent;
  border: none;
  height: 2.5em;
  width: 8em;
  color: black;
  font-size: 0.7em;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0.5em;
  border: 1px solid black;
  font-family: "Josefin Sans", sans-serif;
  transition: color 0.7s ease-in-out, border 0.7s ease-in-out;
  &:hover {
    color: rgb(196, 196, 196);
    border: 1px solid rgb(196, 196, 196);
  }
}

@mixin green_button() {
  background-color: transparent;
  color: #4caf50;
  padding: 1em;
  border: none;
  @include button-style;
  & {
    transition: border-bottom 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  &:hover {
    color: black;
    border-bottom: 1px solid #388e3c;
  }
}
@mixin crinsom_button() {
  background-color: #dc143c;
  padding: 1em 1.4em;
  color: #fff;
  @include button-style;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  &:hover {
    background-color: #ab102f;
    color: white;
  }
}

// implemented for exersices menu style buttoms
@mixin ruby_button() {
  background-color: #e0115f;
  width: 45vh;
  padding: 1.3em;
  color: #fff;
  @include button-style;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  &:hover {
    background-color: #ae0d4b;
    color: white;
  }
}
