body{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font-size: 1.2em;
  &::-webkit-scrollbar {
    width: 0.2em;
  }
  &::-webkit-scrollbar-track{
    background: black;
  }
  // width: 100%;
  // height: auto;
  font-family: 'Josefin Sans', sans-serif;
  
}