

@mixin table_style{
    table {
        width: 95%;
        border-collapse: collapse;
        border: 2px solid rgb(140 140 140);
        font-family: sans-serif;
        font-size: 0.8rem;
        letter-spacing: 1px;
      }
      caption {
        caption-side: bottom;
        padding: 10px;
        font-weight: bold;
      }
      thead,
      tfoot {
        background-color: rgb(228 240 245);
      }
      th,
      td {
        border: 1px solid rgb(160 160 160);
        padding: 8px 10px;
      }
      td:last-of-type {
        text-align: center;
      }
      tfoot th {
        text-align: right;
      }
      tfoot td {
        font-weight: bold;
      }
}
@mixin table_simple_style{
    table {
        width: 95%;
        border-collapse: collapse;
        border: 2px solid rgb(140 140 140);
        font-family: sans-serif;
        font-size: 0.8rem;
        letter-spacing: 1px;
      }
      caption {
        caption-side: bottom;
        padding: 10px;
        font-weight: bold;
      }
      thead,
      tfoot {
        background-color: rgb(228 240 245);
      }
      th,
      td {
        border: 1px solid rgb(160 160 160);
        padding: 8px 10px;
      }
      td:last-of-type {
        text-align: center;
      }
      tfoot th {
        text-align: right;
      }
      tfoot td {
        font-weight: bold;
      }
}