//////////////////////////////////////////////
// 1em = 16px Ex: 576 / 16
// 1em mesurement works better for browsers
// ipad mini 820px
// these variables are used for the media querie manager
$xs: 0;
$sm: 35em; // 576px
$md: 51.25em; // 820px
$lg: 62em; //992px;
$xl: 75em; // 1200px;
$xxl: 1400px;
//////////////////////////////////////////////

// font-size: 1rem = 16px -> if you want 8px, then 8/16*100 result will be in percentage
// @mixin response-phone {
//     @media (max-width: $sm){ @content };
// }

// @mixin reusability for media use
// @content allow to input an entire code block
// @breakpoint is the parameter being passed
// from larger to small order to as expexted
//////////////////////////////////////////////
/// MEDIA QUIERIE MANAGER
@mixin response-media($breakpoint) {
  @if ($breakpoint == xlarge) {
    @media (max-width: $xl) {
      @content;
    } // 820px
  } @else if ($breakpoint == large) {
    @media (max-width: $lg) {
      @content;
    } // 820px
  } @else if($breakpoint == medium) {
    @media (max-width: $md) {
      @content;
    } // 820px
  } @else if($breakpoint == small) {
    @media (max-width: $sm) {
      @content;
    } // 576px
  } @else if($breakpoint == height_phone) {
    @media (max-height: $sm) {
      @content;
    }
  }
}

// use for individual scrollbars
// exported to exercises-style.scss, storage-management.scss,
// nutriclientinfo.js
@mixin scroll-bar-manipulation {
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    // background: rgb(155, 155, 155);
    background: white;
  }
}

@mixin button-style-component($backgroundStyle, $borderStyle) {
  width: 14em;
  position: relative;
  height: 2.4em;
  border: 3px ridge $borderStyle;
  background-color: transparent;
  border-radius: 0.5em;
  // box-shadow: 1px 1px 4px 3px  rgba(0, 0, 255, .2);

  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 3%;
    width: 95%;
    height: 40%;
    background-color: $backgroundStyle;
    transition: 0.4s;
    transform-origin: center;
  }
  &::before {
    content: "";
    transform-origin: center;
    position: absolute;
    top: 80%;
    left: 3%;
    width: 95%;
    height: 40%;
    background-color: $backgroundStyle;
    transition: 0.4s;
  }
  &:hover::after,
  &:hover::before {
    transform: scale(0);
  }
  &:active {
    transform: translateY(1px);
  }
}

//////////////////////////////////////////////
/// MODAL PROPERTIES
//  it creates a sequence of styles to be applied to an
// element over a set of animation points.
@mixin keyframes-response($element) {
  @if ($element == navkeyframes) {
    // navekeyframes is the assigned to keyframes
    @keyframes #{navkeyframes} {
      @content;
    }
  } @else if($element == backframes) {
    @keyframes #{backframes} {
      @content;
    }
  }
}

// the element will smoothly transition through the specified
// keyframe points in keyframes-response(){@content}, applying
// the styles defined at each point, creating the animation effect.
@mixin modal-animation($animation_properties) {
  -webkit-animation: #{$animation_properties};
  -moz-animation: #{$animation_properties};
  -ms-animation: #{$animation_properties};
  -o-animation: #{$animation_properties};
  animation: #{$animation_properties};
}

// center the modal based on the root properties
// in this case: modalWindow is based on the root having (top: 0),
// navmenu based on the root having (top:10) css property in Modal.js
@mixin modal-position($position) {
  @if ($position == modalWindow) {
    top: 0%;
    left: 10%;
    // background based on half width:50% of the dimension of the browser
    // this is for the menu option
  } @else if($position == halfWindow) {
    top: 15%;
    left: 24%;
  }
}

///////////////////////////////////
/// Progress style for modal

@mixin progress-modal() {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: white;
}

///////////////////////////////////
/// Input forms style

@mixin input-style(){
  background-color: transparent;
  border-color: #F7F7F7;
  text-decoration: none;
  border: solid 3px #efefef;
  border-radius: .5rem;
  height: 3em;
  width: 20em;
  color: black;
  text-indent: 0.5em;
  margin: 1em;
  box-shadow: -1px 1px 1px gray;
  font-family: 'Josefin Sans', sans-serif;
}
@mixin textarea-style(){
  background-color: transparent;
  border-color: #F7F7F7;
  text-decoration: none;
  border: solid 3px #efefef;
  border-radius: .5rem;
  height: 15em;
  width: 20em;
  color: black;
  text-indent: 0.5em;
  margin: 1em;
  box-shadow: -1px 1px 1px gray;
  font-family: 'Josefin Sans', sans-serif;
  resize: none;
}
//////////////////////////////////////////
/// close icon style for modals

@mixin spacer{
  width: 100%;
  height: 16vh;
}
@mixin close-icon-wrapper(){
  width: 100%;
  height: 15vh;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: rgb(160, 160, 160);
  
}

//////////////////////////////////////////
/// Icon Style
@mixin icon-style(){
  color: black;
  cursor: pointer;
  transition: color .6s ease-in-out;
  &:hover{
    color: goldenrod;
    
  }
}
//////////////////////////////////////////
/// Card style
@mixin card-style(){
  height: auto;
  color: black;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  overflow: auto;
  padding: 0.4em;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
}